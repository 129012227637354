
import LineMenu from "@/components/LineMenu.vue";
import AtaTmpPat from "@/model/ataTmpPat";
import type EnumValue from "@/model/enums";
import Gender from "@/model/enums/gender";
import News from "@/model/enums/news";
import Status from "@/model/enums/status";
import Checkbox from "primevue/checkbox";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InlineMessage from "primevue/inlinemessage";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
const ataTmpPat = namespace("ataTmpPat");
const recommendedDonors = namespace("recommendedDonors");

type PrimeVueEvent = {
  multiSortMeta: { field: string; order: number }[];
  page: number;
  rows: number;
};

@Options({
  components: {
    LineMenu,
    DataTable,
    Column,
    Dropdown,
    InputText,
    TabView,
    TabPanel,
    InlineMessage,
    Message,
    MultiSelect,
    Checkbox,
  },
})
export default class PatientList extends Vue {
  declare $refs: {
    op: HTMLFormElement;
  };
  ROWS_OPTIONS = [5, 10, 15, 20];
  filter = "";
  ataFilter = "";
  sort = "";
  ataSort = "";
  lastPage = 0;
  lastAtaPage = 0;
  params: Record<string, unknown> = {};
  ataDefSort = [{ field: "createdate", order: -1 }];
  @ataTmpPat.State
  patients!: Array<AtaTmpPat>;
  @ataTmpPat.State
  ataPatients!: Array<AtaTmpPat>;
  @ataTmpPat.State
  patientsCount!: number;
  @ataTmpPat.State
  ataPatientsCount!: number;
  @ataTmpPat.State
  errorWhilePosting!: boolean;
  @ataTmpPat.State
  rows!: number;
  searchDropdown: EnumValue[] = [
    {
      name: "All",
      code: "all",
    },
    {
      name: "Patient Id",
      code: "patientid",
    },
    {
      name: "T-number",
      code: "patientid1",
    },
    {
      name: "Last Name",
      code: "lastname",
    },
    {
      name: "First Name",
      code: "firstname",
    },
  ];
  searchDropdownValue = this.searchDropdown[0].code;
  searchText = "";
  filters = {
    gender: { value: [] as EnumValue["code"][] },
    status: { value: [] as EnumValue["code"][] },
  };
  activeFilter = true;
  inactiveFilter = false;
  favoriteFilter = false;

  Gender = Gender;
  Status = Status;
  News = News;

  async mounted(): Promise<void> {
    await this.ataSortEvent({ multiSortMeta: this.ataDefSort }, false);
    await this.search();
  }

  async pageEvent(event: PrimeVueEvent): Promise<void> {
    this.SET_ROWS(event.rows);
    this.lastPage = event.page !== undefined ? event.page : this.lastPage;
    await this.fetchPatients({
      size: event.rows,
      page: this.lastPage,
      filter: this.filter.replaceAll(
        "lastname~caseinsensitivecontains~",
        "name~caseinsensitivecontains~"
      ),
      sort: this.sort,
      params: this.params,
    });
  }

  async ataPageEvent(event: PrimeVueEvent): Promise<void> {
    this.SET_ROWS(event.rows);
    this.lastAtaPage = event.page !== undefined ? event.page : this.lastAtaPage;
    await this.fetchAtaPatients({
      size: event.rows,
      page: this.lastAtaPage,
      filter: this.ataFilter
        .replaceAll("sex~eq~", "gender~eq~")
        .replaceAll("patstate~eq~", "status~eq~")
        .replaceAll("patstate~neq~", "status~neq~"),
      sort: this.ataSort,
      params: this.params,
    });
  }

  async sortEvent(event: PrimeVueEvent): Promise<void> {
    if (event.multiSortMeta) {
      for (let i = 0; i < event.multiSortMeta.length; i++) {
        if (event.multiSortMeta[i].field == "news")
          event.multiSortMeta[i].order = -1;
      }
    }
    const map = new Map();
    map.set("patientid", "patientid");
    map.set("patientid1", "patientid1");
    map.set("patcenter", "bmdpat2.patcenter");
    map.set("lastname", "name");
    map.set("firstname", "firstname");
    map.set("birthdate", "birthdate");
    map.set("gender", "sex");
    map.set("status", "patstate");
    map.set("news", "news");
    //patientnum&patientnum.dir=asc
    this.sort = event.multiSortMeta
      .map((e) => {
        const f = map.get(e.field);
        return `${f}:${e.order === 1 ? "ASC" : "DESC"}`;
      })
      .join("~");
    await this.pageEvent(event);
  }

  async ataSortEvent(
    event: {
      multiSortMeta: { field: string; order: number }[];
    },
    pageEvent = true
  ): Promise<void> {
    //patientnum&patientnum.dir=asc
    this.ataSort = event.multiSortMeta
      .map((e) => `${e.field}:${e.order === 1 ? "ASC" : "DESC"}`)
      .join("~");
    if (pageEvent)
      await this.ataPageEvent({ page: 0, rows: this.rows, multiSortMeta: [] });
  }

  async search(): Promise<void> {
    const addToFilter = (f: string) => {
      this.filter = this.filter ? `${this.filter}~and~${f}` : f;
      this.ataFilter = this.ataFilter ? `${this.ataFilter}~and~${f}` : f;
    };
    if (this.searchText == "") {
      this.filter = "";
      this.ataFilter = "";
    } else if (this.searchDropdownValue == "all") {
      const f = Object.values(this.searchDropdown).slice(1);
      this.filter = `(${f
        .map((el) => `${el.code}~caseinsensitivecontains~${this.searchText}`)
        .join("~or~")})`;
      this.ataFilter = `(${f
        .filter((el) => el.code != "patientid")
        .map((el) => `${el.code}~caseinsensitivecontains~${this.searchText}`)
        .join("~or~")})`;
    } else {
      this.filter = `${this.searchDropdownValue}~caseinsensitivecontains~${this.searchText}`;
      if (this.searchDropdownValue != "patientid")
        this.ataFilter = `${this.searchDropdownValue}~caseinsensitivecontains~${this.searchText}`;
    }
    if (this.filters.gender.value.length) {
      let f = this.filters.gender.value.map((g) => `sex~eq~${g}`).join("~or~");
      if (this.filters.gender.value.length > 1) f = `(${f})`;
      addToFilter(f);
    }
    if (this.filters.status.value.length) {
      let f = this.filters.status.value
        .map((s) => `patstate~eq~${s}`)
        .join("~or~");
      if (this.filters.status.value.length > 1) f = `(${f})`;
      addToFilter(f);
    }
    if (this.activeFilter) {
      const f = `patstate~eq~${Status.UNKNOWN.code}`;
      if (!this.inactiveFilter) addToFilter(f);
    } else if (this.inactiveFilter) {
      const f = `patstate~neq~${Status.UNKNOWN.code}`;
      addToFilter(f);
    }
    this.params = {};
    if (this.favoriteFilter) {
      this.params["favorite"] = true;
    }
    // console.log(this.filter);
    await this.pageEvent({
      page: this.lastPage,
      rows: this.rows,
      multiSortMeta: [],
    });
    await this.ataPageEvent({
      page: this.lastAtaPage,
      rows: this.rows,
      multiSortMeta: [],
    });
  }

  searchOverlay(event: unknown): void {
    this.$refs.op.toggle(event);
  }

  getNewsRowsStyle(pat: AtaTmpPat): string {
    return pat.news ? "news" : "";
  }

  formatDate(date: string, time = false): string {
    //TODO Add leading 0 to time
    const d = new Date(date);
    return (
      `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}` +
      (time ? ` ${d.getUTCHours()}:${d.getUTCMinutes()}` : "")
    );
  }

  @ataTmpPat.Action
  fetchPatients!: ({
    page,
    size,
    filter,
    sort,
    params,
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
    params?: Record<string, unknown>;
  }) => void;

  @ataTmpPat.Action
  fetchAtaPatients!: ({
    page,
    size,
    filter,
    sort,
    params,
  }: {
    size: number;
    page: number;
    filter?: string;
    sort?: string;
    params?: Record<string, unknown>;
  }) => void;

  @ataTmpPat.Action
  toggleFavorite!: (patient: AtaTmpPat) => void;

  @ataTmpPat.Action
  selectPat!: (patient: AtaTmpPat) => void;

  @ataTmpPat.Action
  addPatient!: () => void;

  @recommendedDonors.Action
  showRecommendedDonors!: (patient: AtaTmpPat) => void;

  @ataTmpPat.Mutation
  SET_ROWS!: (rows: number) => void;
}
